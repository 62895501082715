import mock from '@/fake-db/mock.js'

// Contact
const NotesDb = {
    allNotes: [
        {
            id: 1,
            title: 'Upcoming Session With Kelly',
            text:
                'Reflexology',
            color: 'info lighten-4',
            time: 'Today - @17:30'
        },
        // {
        //     id: 2,
        //     title: 'Upcoming Session With Jhon',
        //     text:
        //         'Physiotherapy',
        //     color: 'success lighten-4'
        // },
        {
            id: 3,
            title: 'Upcoming Session With Kelly',
            text:
                'Massage',
            color: 'warning lighten-4',
            time: 'Tomorrow - @11:00'
        },
        {
            id: 4,
            title: 'Upcoming Session With Jhon',
            text:
                'Reiki',
            color: 'purple lighten-4',
            time: 'Monday 17/07 - @20:00'
        },
        {
            id: 5,
            title: 'Upcoming Session With Jhon',
            text:
                'Massage',
            color: 'danger lighten-4',
            time: 'Tuesday - @13:15'
        }
    ]
}

// GET: Fetch Todos
mock.onGet('/api/notes').reply(() => {
    const response = NotesDb.allNotes
    return [200, response]
})
mock.onPost('/api/notes/add').reply((response) => {
    let note = JSON.parse(response.data).data
    let task = NotesDb.allNotes.push({
        title: note.title,
        text: note.text,
        color: note.color.hex
    })

    return [200, task]
})
mock.onPost('/api/notes/delete').reply((response) => {
    let note = JSON.parse(response.data)
    console.log(note.id)
    NotesDb.allNotes = NotesDb.allNotes.filter((x) => x.id !== note.id)

    return [200, note]
})
