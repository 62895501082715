import firebase from "firebase/app";
import "firebase/auth";
import api from "src/api/index";


export default {
  state: {
    loggedInUser:
      localStorage.getItem("userInfo") != null
        ? JSON.parse(localStorage.getItem("userInfo"))
        : null,
    loading: false,
    error: null,
    selectedLanguage: 
      localStorage.getItem("selectedLanguage") != null
        ? localStorage.getItem("selectedLanguage")
        : null,
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    //loginUserInfo: state => state.loggedInUser != null ? state.loggedInUser.user : null,
    loading: state => state.loading,
    error: state => state.error,
    selectedLanguage: state => state.selectedLanguage,
  },
  mutations: {
    setUser(state, data) {      
      state.loggedInUser = data;
      state.loading = false;
      state.error = null;
    },
    setLogout(state) {
      state.loggedInUser = null;
      state.loading = false;
      state.error = null;
      // this.$router.go("/");
    },
    setLoading(state, data) {
      state.loading = data;
      state.error = null;
    },
    setError(state, data) {
      state.error = data;
      state.loggedInUser = null;
      state.loading = false;
    },
    clearError(state) {
      state.error = null;
    },
    setSelectedLanguage(state, data) {
      state.selectedLanguage = data
      localStorage.setItem("selectedLanguage", data); 
    },
    initialiseStore(state) {
      //console.log(JSON.stringify(state));
    }
  },
  actions: {
    login({ commit }, data) {      
      commit("clearError");
      commit("setLoading", true);      
      api.auth.login(data)
      .then(rsp => {      
        let d = { ...rsp.data }
        delete d.valState;    
        const newUser = { ...d };        
        localStorage.setItem("userInfo", JSON.stringify(newUser));        
        commit("setUser", newUser);                
      })
      .catch(function(rsp) {                                
        localStorage.removeItem("userInfo");
        commit("setError", rsp.response.data.valstate);        
      });
    },

    async signUserUp({ commit }, data) {
      // commit("setLoading", true);
      // commit("clearError");
      // auth.signUp()
      // .then(rspData => {
        
      //   console.log(rspData);
      // })
      // .finally(() => {
      //   commit("setLoading", false);
      // })



      // firebase
      //   .auth()
      //   .createUserWithEmailAndPassword(data.email, data.password)
      //   .then(user => {
      //     commit("setLoading", false);

      //     const newUser = {
      //       uid: user.user.uid
      //     };
      //     console.log(newUser);
      //     localStorage.setItem("userInfo", JSON.stringify(newUser));
      //     commit("setUser", newUser);
      //   })
      //   .catch(error => {
      //     commit("setLoading", false);
      //     commit("setError", error);
      //     localStorage.removeItem("userInfo");
      //     console.log(error);
      //   });
    },
    signOut({ commit }) {
      api.auth.logout()
      .then(rsp => {                
        localStorage.removeItem("userInfo");
        commit("setLogout");        
      })
      .catch(function(rsp) {                
        
      });
    //   firebase
    //     .auth()
    //     .signOut()
    //     .then(
    //       () => {
    //         localStorage.removeItem("userInfo");
    //         commit("setLogout");
    //       },
    //       _error => {}
    //     );
    },
    setLanguage({commit}, data) {
      commit("setSelectedLanguage", data);
    }
  }
};
