import api from "src/api/index";


export default {
    state: {
        onboardingDialogVisible: false,
        toursteps: []
    },
    getters: {
        getTourSteps: state => state.toursteps
        // getTourSteps: state => [
        //     {                
        //               target: '#headeravatar',
        //               content: 'Click here to view your Account details.'
        //             },
        //   ]
    },
    mutations: {
        showOnboardingDialog(state, componentName) {            
            state.onboardingDialogVisible = true;            
        },
        hideOnboardingDialog(state) {
            state.onboardingDialogVisible = false;            
        },
        updateOnboardingDialogVisible(state, value) {                
            state.onboardingDialogVisible = value;
        },
        setTourSteps(state, value) {
            // alert(JSON.stringify(value));
            state.toursteps.splice(0);
            state.toursteps.push(...value);
        }
    },
    actions: {

    }
}