import { HTTPAPI } from "../base";

const grabToken = () => {
    const uInfoTxt = localStorage.getItem("userInfo");    
    let uInfo = null;
    if(uInfoTxt) {
        uInfo = JSON.parse(uInfoTxt);
    }
    const token = uInfo != null ? uInfo.token : "";    
    return token;
}

const adpoint = {
    
    getList: (urlCriteria) => {        
        return HTTPAPI(grabToken())
        .get("/adpoint" + urlCriteria)
    },
    // invite: (data) => {
    //     return HTTPAPI(grabToken())
    //     .post("/users/invite", data)
    // },
    // getUser: (code) => {        
    //     return HTTPAPI(grabToken())
    //     .get("/users/" + code)
    // },
    // invite: (data) => {
    //     return HTTPAPI(grabToken())
    //     .post("/users/invite", data)
    // },
    // update: (code, data) => {
    //     return HTTPAPI(grabToken())
    //     .patch("/users/" + code, data);
    // },
    // disable: (code, data) => {
    //     return HTTPAPI(grabToken())
    //     .patch("/users/disable/" + code, data);
    // },
    // reset: (code) => {
    //     return HTTPAPI(grabToken())
    //     .get("/users/reset/" + code);
    // }
    

    getInitData: () => {
        return HTTPAPI(grabToken())
        .get("/adpoint/initdata")
    },

    getInitEditData: (code) => {
        if(code) {
            return HTTPAPI(grabToken())
            .get("/adpoint/initeditdata/" + code);
        }
        return HTTPAPI(grabToken())
        .get("/adpoint/initeditdata");
    },
    update: (code, data) => {
        return HTTPAPI(grabToken())
        .patch("/adpoint/" + code, data);
    },
    create: (data) => {
        return HTTPAPI(grabToken())
        .post("/adpoint/", data);
    },
    createfiltertemplate: (data) => {
        return HTTPAPI(grabToken())
        .post("/adpoint/filtertemplate", data);
    },
    getTemplates: () => {
        return HTTPAPI(grabToken())
        .get("/adpoint/filtertemplate")
    },
    getTemplateDetails: (code) => {
        if(code) {
            return HTTPAPI(grabToken())
            .get("/adpoint/filtertemplate/" + code);
        }
    },



    // signUp: (data) => {
    //     return HTTPAPI()
    //     .post("/users/register", data)
    // },
    // activateUser: (code) => {
    //     return HTTPAPI()
    //     .post("/users/activate", {code})
    // },
    // login: (data) => {
    //     return HTTPAPI()
    //     .post("/auth/login", data)
    // },    
    // logout: (data) => {        
    //     return HTTPAPI(grabToken())
    //     .post("/auth/logout", data)
    // },
    // forgotPass: (data) => {
    //     return HTTPAPI()
    //     .post("/users/forgotpass", data)
    // },
    // validateResetPassCode: (code) => {
    //     return HTTPAPI()
    //     .post("/users/validatepassreset", {code})
    // },
    // changePassword: (data) => {
    //     return HTTPAPI()
    //     .post("/users/changepass", data)
    // }
}

export default adpoint;
